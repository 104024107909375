.feature {
  @apply w-full relative mt-10 lg:mt-12;

  & .container-full {
    @apply relative overflow-x-hidden;
  }

  & .container-post {
    @apply relative;
    max-width: 1440px;

    & .post-it {
      @apply bg-primary-light w-56 h-24 flex items-center justify-center text-2xl font-extrabold absolute  left-24  z-40  -mt-14;
      transform: rotate(18deg);

      @media (min-width: 500px) {
        @apply left-32;
      }

      @media (min-width: 600px) {
        @apply left-44;
      }

      @media (min-width: 700px) {
        @apply left-56;
      }

      @media (min-width: 800px) {
        @apply left-64 ml-2;
      }

      @media (min-width: 900px) {
        @apply left-72 ml-6;
      }

      @media (min-width: 1000px) {
        @apply left-96 ml-6;
      }

      @media (min-width: 1100px) {
        @apply ml-10 -mt-16;
      }

      @media (min-width: 1200px) {
        @apply left-32 ml-0 -mt-14;
      }

      @media (min-width: 1300px) {
        @apply left-56 ml-0;
      }
    }
  }
}
