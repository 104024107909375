.benefit {
  @apply w-full flex justify-center relative;

  & .container-line {
    @apply relative;

    max-width: 1440px;

    & .line-between {
      @apply absolute ml-3 left-40   xl:left-80 2xl:ml-24 top-0 -mt-6  md:-mt-6  lg:-mt-8;

      @media (min-width: 500px) {
        @apply left-56;
      }

      @media (min-width: 600px) {
        @apply left-72;
      }

      @media (min-width: 700px) {
        @apply left-80;
      }

      @media (min-width: 800px) {
        @apply left-96;
      }

      @media (min-width: 900px) {
        @apply ml-14 -mt-7;
      }

      @media (min-width: 1000px) {
        @apply ml-24 -mt-10;
      }

      @media (min-width: 1100px) {
        @apply ml-36 -mt-10;
      }

      @media (min-width: 1200px) {
        @apply ml-5 -mt-8 left-56;
      }

      @media (min-width: 1300px) {
        @apply ml-24;
      }

      @media (min-width: 1400px) {
        margin-left: 6.5rem;
      }
    }
  }

  & .container-full {
    @apply w-full pl-4 md:px-20 xl:px-28 relative pt-52 flex flex-col items-center;

    @media (min-width: 1400px) {
      @apply px-40;
    }

    & .post-it-benefit {
      @apply bg-primary-main w-64 h-24 flex justify-center items-center font-extrabold text-2xl absolute  top-0 mt-28 z-20;
      transform: rotate(-10deg);
      border-radius: 192px;

      @media (min-width: 1200px) {
        @apply left-0 ml-48;
      }
    }

    & .cards-benefit {
      @apply w-full flex justify-between lg:flex-wrap overflow-x-scroll z-10 relative;

      & .card-benefit {
        @apply mr-4 lg:mr-0 lg:w-auto mb-9;

        min-width: 350px;
      }
    }

    & .cards-benefit::-webkit-scrollbar {
      display: none;
    }

    & button {
      @apply bg-secondary-main w-48 mt-5 h-12 text-white text-base font-extrabold;
      border-radius: 47px;
    }

    & button:hover {
      @apply bg-secondary-dark;
    }
  }
}
