@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/rew3yjz.css");

body {
  font-family: "Manrope";
}

.content {
  @apply flex justify-center;
}

.container-full {
  max-width: 1440px;
}
