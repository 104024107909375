.pricing {
  @apply w-full mt-20  bg-black flex justify-center;
  border-top-right-radius: 168px;

  & .container-full {
    @apply pt-20  xl:px-44 w-full;

    & .title-pricing {
      @apply text-3xxl font-extrabold text-primary-main mt-1 w-3/4 pr-1 px-8;
    }

    & .subtitle-pricing {
      @apply text-lg font-extrabold text-white w-11/12 lg:w-3/5 mt-4 px-8;
    }

    & .info-price {
      background: url("../../assets/img/pricing.svg");
      background-position: center top;

      @apply relative mt-20 bg-no-repeat pb-32 flex flex-col items-center bg-cover;

      & .card-price {
        @apply flex justify-between pt-14  lg:px-8 w-full overflow-scroll;

        & .card {
          @apply lg:w-1/3 ml-4;

          width: 302px;
        }

        & .card:last-child {
          @apply mr-4;
        }
      }

      & .card-price::-webkit-scrollbar {
        display: none;
      }
    }

    & button {
      @apply bg-secondary-main mt-14 text-white py-3 px-14;
      border-radius: 47px;
    }

    & button:hover {
      @apply bg-secondary-dark;
    }
  }
}
