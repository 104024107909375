.nav {
  @apply flex   justify-around;

  & .link {
    @apply text-secondary-main font-extrabold  cursor-pointer;
  }

  & .link:not(:first-child) {
    @apply ml-14;
  }

  & .link:hover {
    @apply opacity-70;
  }
}
