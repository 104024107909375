.menu-mobile {
  @apply fixed top-0 bottom-0 right-0 left-0;
  background: rgba(24, 31, 28, 0.24);
  backdrop-filter: blur(8px);

  & .body-mobile {
    @apply bg-secondary-main;
    border-bottom-right-radius: 32px;

    & .header-menu-mobile {
      @apply flex justify-between mr-7;
    }

    & .list-menu-mobile {
      @apply border-t-2 border-base-white px-11 pt-14 pb-11  flex  flex-col items-center;

      & .list-nav {
        @apply flex  flex-col items-center;

        & .link {
          @apply text-white text-3xxl font-extrabold mb-10;
        }

        & .svg-link * {
          fill: white;
        }
      }

      & button {
        @apply border-white w-full text-base-white;
      }

      & .list-link-mobile {
        @apply flex mt-16 justify-between w-2/3 items-center;
      }
    }
  }
}
