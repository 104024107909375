.carousel {
  @apply p-6 lg:p-8 mx-auto;
  width: 90%;

  & .slick-slider {
    & .slick-arrow {
      @apply bg-secondary-main rounded-full h-14 w-14;

      display: none !important;

      @media (min-width: 600px) {
        display: block !important;
      }
    }

    & .slick-arrow:hover {
      @apply bg-secondary-dark;
    }

    & .slick-arrow:before {
      @apply text-base-white font-extrabold text-2xl opacity-100;
      font-family: "Manrope";
      content: " \279C";
    }

    & .slick-next {
      @apply -right-8;
    }

    & .slick-prev {
      @apply z-10 -left-8;
    }

    & .slick-prev:before {
      @apply transform rotate-180 block;
    }

    & .slick-disabled {
      @apply opacity-40;
    }

    & .slick-dots {
      @apply bottom-8;

      & li {
        & button:before {
          @apply text-base text-base-light-gray opacity-100;
        }
      }

      & .slick-active {
        & button:before {
          @apply text-primary-light;
        }
      }
    }

    & .slick-list {
      @apply rounded-2xl;
    }
  }

  & .content-slider {
    @apply bg-base-black pt-24 lg:pt-10 pb-20 px-4 lg:px-28   h-full;

    @media (min-width: 768px) {
      display: flex !important;
    }

    & .text-slider {
      @apply text-white lg:w-1/2 py-2 px-10 flex flex-col  justify-center;

      height: 456px;

      @media (min-width: 768px) {
        height: auto;
      }

      & h1 {
        @apply text-2xl font-extrabold;
      }

      & p {
        @apply text-base mt-4;
      }
    }

    & .img-slider {
      @apply w-full lg:w-1/2 flex justify-center lg:justify-end lg:pr-24 mt-6 lg:mt-0;

      & img {
        border-radius: 64px;
      }
    }
  }
}
