.card {
  @apply bg-white py-10 px-8 flex flex-col items-center rounded;
  max-width: 300px;

  & .title-card {
    @apply text-3xxl text-secondary-main font-extrabold;
  }

  & .options {
    @apply w-full;

    & ul {
      & li {
        @apply flex justify-between mt-6 font-extrabold text-lg;
      }
    }
  }

  & .value {
    & p {
      @apply text-secondary-main mt-12 text-5xl font-extrabold;

      & span {
        @apply text-lg;
      }
    }
  }
}
