.footer {
  @apply w-full flex justify-center;
  background: #2f3133;

  & .container-full {
    @apply w-full px-7 xl:px-28 pt-14 pb-16 flex justify-between items-center flex-col lg:flex-row;

    & .links {
      @apply flex flex-col items-center;

      & .list {
        @apply flex items-center cursor-pointer;

        & a {
          @apply mr-6;
        }
      }
    }

    & .external-links {
      @apply flex  flex-col lg:flex-row justify-between w-full lg:w-10/12 lg:pl-36 items-end;

      & .info-links {
        @apply flex flex-col pt-20 w-full lg:w-auto items-center;

        & a {
          @apply text-base-light-gray text-sm underline mb-4;
        }
      }

      & p {
        @apply text-base font-semibold mt-10 text-center w-full lg:w-auto;
        color: #606466;
        font-family: "Raleway", sans-serif;
      }
    }
  }
}
