.video {
  @apply rounded-2xl border-8 border-white;

  width: 356px;
  height: 221px;

  @media (min-width: 600px) {
    width: 450px;
    height: 265px;
  }

  @media (min-width: 1024px) {
    width: 644px;
    height: 406px;
  }

  filter: drop-shadow(0px 5px 10px rgba(102, 102, 102, 0.2));

  & iframe {
    @apply rounded-xl w-full h-full;
  }
}
