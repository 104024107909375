.card-benefit {
  @apply bg-base-light-gray p-8 rounded-2xl;

  max-width: 350px;

  & h1 {
    @apply text-2xl font-extrabold;
  }

  & p {
    @apply text-base mt-2;
  }
}
