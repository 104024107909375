.header {
  @apply bg-base-white w-full lg:py-3 h-18 lg:h-24 fixed z-50;

  & .container-full {
    @apply flex items-center justify-between px-5  xl:px-20 w-full;

    & .logo {
      @apply hidden lg:block lg:h-full lg:w-48;
    }

    & .logo-menu {
      @apply lg:hidden;
    }

    & button {
      @apply text-secondary-main border-2 border-secondary-main h-12 w-48 cursor-pointer font-bold text-base;

      border-radius: 47px;
    }

    & button:hover {
      @apply bg-secondary-main text-white;
    }

    & .header-nav {
      @apply items-center hidden;

      @media (min-width: 1200px) {
        @apply flex;
      }

      & button {
        @apply ml-32;
      }
    }

    & .header-mobile {
      @apply items-center justify-center;
    }

    & .button-menu {
      @media (min-width: 1200px) {
        @apply hidden;
      }
    }
  }
}
