.about {
  @apply relative w-full mt-40 xl:mt-20 flex justify-center px-6 lg:px-0;

  & .container-full {
    @apply w-full px-0 lg:px-10 xl:px-32 flex flex-col justify-start xl:justify-center items-center z-10 overflow-hidden xl:overflow-visible border-b-2 border-secondary-main xl:border-0;
    height: 600px;

    @media (min-width: 1100px) {
      height: auto;
    }

    @media (min-width: 1400px) {
      @apply px-40;
    }

    & .title-about {
      @apply text-3xxl font-extrabold text-left w-full;
    }

    & .cards-about {
      @apply relative w-full  flex flex-col items-center;

      & .cards-body {
        @apply h-full overflow-hidden flex justify-center flex-col items-center;

        & .cards {
          @apply lg:flex w-full mt-6  relative justify-between;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-flow: column wrap;

          @media (min-width: 1180px) {
            height: 510px;
          }

          & .card-about {
            @apply mb-4;
          }
        }
      }
    }

    & h2 {
      @apply mt-14 lg:w-2/3 text-2xl font-extrabold w-full pr-4;
    }
  }

  & button {
    @apply xl:hidden absolute bottom-0  flex border-2 border-secondary-main text-secondary-main text-base w-64 h-12 justify-center items-center font-extrabold bg-base-white -mb-6 z-20;
    border-radius: 47px;

    & svg {
      @apply ml-3;
    }
  }

  & .container-heart {
    @apply absolute w-full;

    max-width: 1440px;

    & .heart {
      @apply absolute -top-20 lg:-top-40 -right-40 lg:right-0;
    }
  }

  & .show_hidden {
    @apply h-full pb-20;
  }

  & .show_hidden_button {
    & svg {
      @apply transform rotate-180;
    }
  }
}
