.hero {
  @apply bg-base-white w-full  overflow-hidden pt-24;

  & .container-full {
    @apply px-6  lg:px-10 xl:px-40 w-full relative;

    & h1 {
      @apply text-5xl font-extrabold mt-14 lg:mt-16 w-11/12;
      line-height: 60px;

      & .flip {
        height: 60px;
        overflow: hidden;
      }

      & .flip div {
        @apply text-secondary-main;

        height: 45px;
        margin-bottom: 45px;
      }

      & .flip div:first-child {
        animation: show 10s linear infinite;
      }
    }

    & .info-video {
      @apply xl:flex mt-6;

      & .info {
        @apply xl:w-1/2 lg:pr-18;

        & .intro {
          & h2 {
            @apply text-lg font-extrabold overflow-x-visible hidden xl:block;
            width: 562px;
          }

          & p {
            @apply text-base mt-2;

            & span {
              @apply font-bold;
            }
          }
        }

        & .intro-link {
          @apply mt-10 lg:mt-12 flex flex-col items-center xl:w-11/12;

          & h3 {
            @apply text-lg lg:text-2xl font-extrabold;

            & span {
              @apply text-secondary-main;
            }
          }

          & button {
            @apply bg-secondary-main text-white text-base font-bold h-12 w-64 xl:w-48 mt-14 lg:mt-8;
            border-radius: 47px;
          }

          & button:hover {
            @apply bg-secondary-dark;
          }
        }
      }

      & .video-dots {
        @apply relative w-full xl:w-1/2 sm:-right-10 xl:-right-40 pt-8 lg:pt-4;
        height: 288px;

        @media (min-width: 600px) {
          height: 448px;
        }

        @media (min-width: 1030px) {
          height: 538px;
        }

        @media (min-width: 1200px) {
          height: 608px;
        }

        & svg {
          @apply absolute  -right-28 sm:right-0 w-60  h-full sm:w-auto xl:top-0;
        }

        & .video {
          @apply absolute mt-7 right-0 xl:mt-24 xl:-right-10 xl:right-0 z-20 sm:mr-18;
        }
      }
    }
  }
}

@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}
