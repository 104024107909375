.connect {
  @apply -mt-20 lg:-mt-52 relative overflow-hidden flex justify-center;
  height: 345px;

  & div {
    @apply flex justify-center;

    & svg {
      @apply absolute overflow-x-hidden w-full  xl:-mr-24;

      @media (min-width: 1200px) {
        @apply w-auto;
      }

      @media (min-width: 1400px) {
        @apply mr-0 w-full;
      }
    }
  }

  & .logo-min {
    @media (min-width: 1200px) {
      @apply hidden;
    }
  }

  & .logo-max {
    @apply hidden;
    @media (min-width: 1200px) {
      @apply block;
    }
  }
}
